<template>
  <div>
    <add-new-payment-method
        @payment-method-added="fetchPaymentMethods"
    />
    <div class="location-settings-content billing-page">

      <div class="settings-card overflow-hidden mb-2">
        <div class="card-header d-flex justify-content-between  align-items-center">
          <div class="card-title">
            <div v-if="!subscription_loading">
              <h4 :class="{'text-danger':lostAccess}">{{ billing_message_title }}
              </h4>
              <div style="margin-top:6px">
                {{ billing_message_text }}
              </div>
            </div>
            <div v-else>
              <skeleton loading="true" class="d-block" style="width:260px;"/>
              <skeleton loading="true" class="d-block" style="width:80px;"/>
            </div>
          </div>
          <div class="overflow-hidden">

            <b-button
                v-if="subscription_status!='canceled'"
                variant="flat-danger"
                :disabled="subscription_loading"
                class=" btn-sm-block mb-0"
                @click="cancelSubscription"
            >
              Cancel Subscription
            </b-button>
            <b-button
                v-else
                :disabled="subscription_loading"
                variant="flat-primary"
                class=" btn-sm-block mb-0"
                @click="resumeSubscription"
            >
              Resume Subscription
            </b-button>
          </div>
        </div>
      </div>


      <div class="settings-card">
        <div class="card-header d-flex justify-content-between  align-items-center">
          <div class="card-title">
            <h4>Payment Methods</h4>
          </div>
          <div>
            <b-button
                variant="primary"
                class=" btn-sm-block mb-0"
                v-b-modal.add-new-payment
            >
              <feather-icon icon="PlusIcon"/>
              Add New
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <div v-for="payment_method in payment_methods" class="cc-card-box">
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div class="cardholder-name">
                  <span v-if="!loading">
                  {{ payment_method.billing_details.name }}
                    </span>
                  <skeleton v-else loading="true" class="d-block" style="width:110px"/>

                </div>
                <div class="primary-card-label"
                     v-if="!loading && default_payment_method && default_payment_method.id === payment_method.id ">
                  Primary
                </div>
              </div>
              <div class="d-flex align-items-center mt-1">
                <div v-if="!loading" class="card-type-box">
                  <svg width="57" height="19" v-if="payment_method.card.brand=='visa'" viewBox="0 0 57 19" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.71 18.6833H20.059L22.9658 0.327984H27.6174L24.71 18.6833ZM16.1462 0.327984L11.712 12.9529L11.1874 10.2343L11.1878 10.2353L9.62281 1.98067C9.62281 1.98067 9.43358 0.327984 7.4165 0.327984H0.0860162L0 0.638783C0 0.638783 2.24168 1.11799 4.86517 2.7368L8.90601 18.6838H13.7521L21.1518 0.327984H16.1462ZM52.7293 18.6833H57L53.2765 0.327493H49.5376C47.8111 0.327493 47.3906 1.6954 47.3906 1.6954L40.4538 18.6833H45.3023L46.2719 15.9568H52.1845L52.7293 18.6833ZM47.6113 12.1904L50.0552 5.32139L51.43 12.1904H47.6113ZM40.8175 4.74202L41.4813 0.800319C41.4813 0.800319 39.4331 0 37.298 0C34.9899 0 29.5088 1.03649 29.5088 6.07654C29.5088 10.8186 35.9418 10.8775 35.9418 13.3683C35.9418 15.8591 30.1716 15.4128 28.2673 13.8421L27.5758 17.9635C27.5758 17.9635 29.6526 19 32.8257 19C35.9996 19 40.7879 17.3115 40.7879 12.7158C40.7879 7.9433 34.297 7.49895 34.297 5.42401C34.2975 3.34858 38.8272 3.61519 40.8175 4.74202Z"
                        fill="#2566AF"/>
                    <path
                        d="M11.1878 10.2348L9.62279 1.98021C9.62279 1.98021 9.43355 0.327524 7.41648 0.327524H0.086016L0 0.638323C0 0.638323 3.52331 1.38856 6.90278 4.1995C10.1341 6.88622 11.1878 10.2348 11.1878 10.2348Z"
                        fill="#E6A540"/>
                  </svg>
                  <svg width="51" v-else height="31" viewBox="0 0 51 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.3995 27.6747H18.6076V3.31226H32.3998L32.3995 27.6747Z" fill="#FF5F00"/>
                    <path
                        d="M19.482 15.4933C19.482 10.5514 21.8362 6.14911 25.502 3.31209C22.7272 1.16144 19.2951 -0.00578667 15.7613 -0.000654535C7.05636 -0.000654535 0 6.93612 0 15.4933C0 24.0505 7.05636 30.9873 15.7613 30.9873C19.2951 30.9925 22.7273 29.8252 25.5023 27.6745C21.8367 24.838 19.482 20.4355 19.482 15.4933Z"
                        fill="#EB001B"/>
                    <path
                        d="M51.0064 15.494C51.0064 24.0511 43.95 30.9879 35.2451 30.9879C31.7109 30.993 28.2783 29.8258 25.5028 27.6752C29.1697 24.8382 31.5239 20.4361 31.5239 15.494C31.5239 10.5518 29.1697 6.14975 25.5028 3.31273C28.2782 1.16215 31.7107 -0.00505585 35.2448 -1.42535e-05C43.9498 -1.42535e-05 51.0062 6.93677 51.0062 15.494"
                        fill="#F79E1B"/>
                  </svg>


                </div>
                <skeleton v-else loading="true" class="card-type-box"/>

                <div class="ml-1">
                  <div class="card-abbr">
                    <!-- First letter upercase -->
                    <span v-if="!loading">{{
                        capitalizeFirstLetter(payment_method.card.brand)
                      }} **** {{ payment_method.card.last4 }}</span>
                    <skeleton v-else loading="true" class="d-block" style="width:135px"/>
                  </div>
                  <div class="card-exp">
                    <span v-if="!loading">Card expires at {{
                        payment_method.card.exp_month
                      }}/{{ payment_method.card.exp_year }}</span>
                    <skeleton v-else loading="true" class="d-block" style="width:155px"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center"
                 v-if="(!default_payment_method || default_payment_method.id !== payment_method.id) && !loading">

              <b-button
                  variant="flat-primary "
                  :disabled="make_primary_loading === payment_method.id"
                  @click="makeDefault(payment_method.id)">
                <template>
                  <span v-if="make_primary_loading !== payment_method.id">Make Primary</span>
                  <span v-else>Loading...</span>
                </template>
              </b-button>
              <b-button
                  @click="deletePaymentMethod(payment_method.id)"

                  variant="flat-danger"
              >
                <template>
                  <span v-if="deleting_payment_method !== payment_method.id">
                                        <feather-icon icon="TrashIcon"></feather-icon>
                  </span>
                  <span v-else>
                    Deleting...
                  </span>
                </template>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton, BAlert} from "bootstrap-vue";
import AddNewPaymentMethod from "@/layouts/components/front/AddNewPaymentMethod.vue";
import PaymentsService from "@/services/PaymentsService";
import {Skeleton} from "vue-loading-skeleton";

export default {
  name: 'BillingSettings',
  components: {
    BButton,
    BAlert,
    AddNewPaymentMethod,
    Skeleton

  },
  computed: {
    nextBillingAmountFormatted() {
      let amount = parseInt(this.nextBillingAmount) / 100;
      return "$" + amount.toFixed(2);
    },
  },
  data() {
    return {
      payment_methods: [{}, {}],
      default_payment_method: null,
      make_primary_loading: false,
      loading: true,
      nextBillingDate: null,
      lostAccess: false,
      planPrice: null,
      nextBillingAmount: null,
      subscription_status: null,
      subscription_loading: true,
      deleting_payment_method: false,
      billing_message_title: null,
      billing_message_text: null,

    }
  },
  methods: {
    async fetchPaymentMethods() {
      this.loading = true;
      // Fill with 2 fake cards
      this.payment_methods = [{}, {}];


      const {data: {payment_methods, default_payment_method}} = await PaymentsService.get.paymentMethods();
      this.payment_methods = payment_methods;
      this.default_payment_method = default_payment_method;
      this.loading = false
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async deletePaymentMethod(payment_method_id) {
      const {isConfirmed} = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger mt-1',
          cancelButton: 'btn btn-outline-primary mt-1 ml-1',
        },
        buttonsStyling: false,
      });

      if (!isConfirmed) return;
      this.deleting_payment_method = payment_method_id;
      try {
        const {data: {success, payment_methods}} = await PaymentsService.delete.paymentMethod(payment_method_id);
        if (success) {
          this.payment_methods = payment_methods
          this.showSuccessToast('Payment method successfully deleted');
        }
      } catch (e) {
        this.showErrorToast('Error deleting payment method');
      }


      this.deleting_payment_method = false;

    },
    async makeDefault(payment_method_id) {
      this.make_primary_loading = payment_method_id;
      const {data: {payment_method}} = await PaymentsService.post.makeDefault(payment_method_id);
      this.default_payment_method = payment_method
      this.make_primary_loading = false
      this.showSuccessToast('Payment method successfully set as default');
    },
    async fetchSubscriptionInfo() {
      this.subscription_loading = true;
      const {
        data: {
          next_billing_at,
          lost_access,
          plan_price,
          upcoming_invoice,
          subscription_status,
          billing_message_title,
          billing_message_text

        }
      } = await PaymentsService.get.subscriptionInfo();
      this.lostAccess = lost_access;
      this.planPrice = plan_price;
      this.nextBillingDate = next_billing_at;
      if (upcoming_invoice) {
        this.nextBillingAmount = upcoming_invoice.total;
      }

      this.billing_message_title = billing_message_title;
      this.billing_message_text = billing_message_text;


      this.subscription_status = subscription_status;
      this.subscription_loading = false;
    },
    async resumeSubscription() {
      this.subscription_loading = true

      await PaymentsService.post.resumeSubscription();
      await this.fetchSubscriptionInfo()

      this.subscription_loading = false
      this.showSuccessToast('Subscription successfully resumed');
    },
    async cancelSubscription() {
      const {isConfirmed} = await this.$swal({
        title: 'Are you sure?',
        text: "You will still have access to the platform until " + this.nextBillingDate + ", but after that you will not be able to access the  platform until you resume your subscription.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        customClass: {
          confirmButton: 'btn btn-danger mt-1',
          cancelButton: 'btn btn-outline-primary mt-1 ml-1',
        },
        buttonsStyling: false,
      });

      if (!isConfirmed) return;

      this.subscription_loading = true

      await PaymentsService.post.cancelSubscription();
      await this.fetchSubscriptionInfo()

      this.subscription_loading = false


      this.showSuccessToast('Subscription successfully cancelled');
    },
  },
  mounted() {
    this.fetchPaymentMethods()
    this.fetchSubscriptionInfo()

  }
}
</script>
